const moment = require('moment');

const getPath = () => {
    let scripts = document.querySelectorAll('script'),
        path = ""
    scripts.forEach(script => {
        if(script.src.includes('cookies.js', -1)){
            path = script.src.substring(0, script.src.indexOf('/javascript/cookies.js'));
        }
    });
    return path
}

const getCookie = (cookieName) => {
    let name = cookieName + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return
}

const getAgent = () => {
    let na = navigator.userAgent;
	let agent;
    if(na.indexOf("Trident") > 0){
        agent = "InternetExplorer";
    }
    else if(na.indexOf("Edge") > 0){
        agent = "Edge";
    }
    else if(na.indexOf("OPR") > 0){
        agent = "Opera";
    }
    else if(na.indexOf("Chrome") > 0){
        agent = "Chrome";
    }
    else if(na.indexOf("Firefox") > 0){
        agent = "Firefox";
    }
    else if(na.indexOf("Safari") > 0){
        agent = "Safari";
    }

    if(na.indexOf("Mobile") > 0){
        agent = agent+"/Mobile";
    }
    if(na.indexOf("FB_IAB") > 0){
        agent = agent+"/Facebook";
    }

    return agent
}

const setCookie = (cookieName, value) => {
    let domain = window.location.hostname
    if(!window.location.hostname.includes('localhost')){
       domain = '.' + window.location.hostname
    }
    let expire = moment().add(5, 'months').format('X')
    document.cookie = cookieName + '=' + value + ";domain=" + domain + '; expires=' + expire +'; path=/'   
}

export { getCookie, setCookie, getPath, getAgent }