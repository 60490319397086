import { setCookie } from './tools'

class Cookie {

    constructor (bar, box) {
        this.bar = bar
        this.box = box
        this.cross = box.querySelector('.cookiebox-close')
        this.submit = box.querySelector('#cookiebox-submit')
        this.mapping = []
        this.prefix = cookiesPrefix
    }

    registerEvents() {
        this.bar.addEventListener('click', this.handleCookiebar.bind(this))
        this.box.addEventListener('click', this.handleCookiebox.bind(this))
    }

    handleCookiebar (e) {
        let el = e.target

        if (el.classList.contains('cookiebar-custom'))
            return this.openCookiebox(el)

        if (el.classList.contains('cookiebar-valid'))
            return this.removeCookiebar()
    }

    handleCookiebox (e) {
        
        let el = e.target

        if (el.classList.contains('cookiebox-close'))
            this.closeCookiebox(el)

        if (el.type === 'submit') {
            e.preventDefault()
            this.setRgpdCookie()
        }
    }

    removeCookiebar () {
        this.bar.style.display = 'none'
        this.setRgpdCookie()
    }

    openCookiebox (button) {
        this.box.classList.add('trigger')
        button.setAttribute('aria-expanded', 'true')
        this.cross.focus()
    }
    
    closeCookiebox (button) {
        this.box.classList.remove('trigger')
        button.removeAttribute('aria-expanded')
    }

    setRgpdCookie () {
        let form = this.box.querySelector('form')
        let inputs = form.querySelectorAll('input')
        let cookieValue = "{"
        inputs.forEach(input => {
            cookieValue += '"' + input.id + '":' + input.checked + ","
        });
        cookieValue = cookieValue.slice(0, -1);
        cookieValue += "}"
        setCookie(this.prefix + '_rgpd', cookieValue)
        this.bar.style.display = 'none'
        this.box.style.display = 'none'
    }
}

export {Cookie}