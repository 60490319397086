import { getCookie } from './tools'
import * as _cookie from './cookie';

export default (path) => {

    //Get and add cookies.css
    let link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = path + '/css/cookies.css';
    document.head.appendChild(link);

    //Get and add cookies banner
    let reqBanner = new XMLHttpRequest()
    reqBanner.open('GET', path + '/Templates/cookies-banner.php', true);
    reqBanner.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    reqBanner.send();
    reqBanner.onreadystatechange = function () {
        if (reqBanner.readyState == XMLHttpRequest.DONE) {
            if (reqBanner.status == 200) {
                let div = document.createElement('div')
                div.innerHTML = reqBanner.responseText
                document.body.appendChild(div)

                //Init cookie object
                if(getCookie(cookiesPrefix + '_rgpd') == 'false'){
                    let cookieSetup = new _cookie.Cookie(document.querySelector('.cookiebar'), document.querySelector('.cookiebox'))
                    cookieSetup.registerEvents()
                }
            }
        }
    }

    //Get and add banner colors css
    let reqBannerCss = new XMLHttpRequest()
    reqBannerCss.open('GET', path + '/Templates/banner-colors.php', true);
    reqBannerCss.send();
    reqBannerCss.onreadystatechange = function () {
        if (reqBannerCss.readyState == XMLHttpRequest.DONE) {
            if (reqBannerCss.status == 200) {
                let style = document.createTextNode(reqBannerCss.responseText)
                let styleColors = document.createElement('style')
                styleColors.type = 'text/css';
                styleColors.appendChild(style)
                document.head.appendChild(styleColors);
            }
        }
    }

    //Get and add tag manager
    let reqTagManager = new XMLHttpRequest()
    reqTagManager.open('GET', path + '/Templates/tag-manager.php', true);
    reqTagManager.send();
    reqTagManager.onreadystatechange = function () {
        if (reqTagManager.readyState == XMLHttpRequest.DONE) {
            if (reqTagManager.status == 200) {
                let script = document.createTextNode(reqTagManager.responseText)
                let scriptGtag = document.createElement('script')
                scriptGtag.appendChild(script)
                document.head.appendChild(scriptGtag);
            }
        }
    }
}