import polyfill from './module/polyfill';
import init from './module/init';
import Tracking from './module/tracking';
import { getCookie, getPath } from './module/tools'

(function () {
    'use strict'

    polyfill();

    let path = getPath()

    window.addEventListener('load', () => {
        if(cookiesCurrentCampaigns != undefined){
            if(cookiesCurrentCampaigns[0] != ""){
                Tracking(path);
            }
        }        
        init(path);
    })
})()