import { getCookie, getAgent } from './tools'

let bufferMouse = ""
let bufferID = 0
let mouseMovement = []

let bufferScroll = 0
let stepScroll = []

const trackMouse = (currentMousePath) => {
    if(currentMousePath === bufferMouse) return
    const now = new Date()
    if(mouseMovement[bufferID - 1] !== undefined){
        if(mouseMovement[bufferID - 1].leave === null || mouseMovement[bufferID - 1].leave === undefined){
            mouseMovement[bufferID - 1].leave = now.getTime()
        }
    }
    if(currentMousePath.id != ""){
        mouseMovement.push({
            element : currentMousePath.id,
            enter : now.getTime()
        })
        bufferID += 1
    }
    bufferMouse = currentMousePath
}

const trackScroll = () => {
    const scroll = window.pageYOffset || document.documentElement.scrollTop
    const percent = (scroll / ( document.body.scrollHeight - window.innerHeight ) ) * 100

    if(percent - bufferScroll >= 10 || percent - bufferScroll < -10){
        stepScroll.push({
            step: Math.floor(percent / 10) * 10,
            timestamp: new Date().getTime()
        })
        bufferScroll = Math.floor(percent / 10) * 10
    }
}

const save = (tracking, path) => {
    let url  = path + '/Functions/save-scroll-data.php'

    let httpRequest = new XMLHttpRequest();
    httpRequest.open('POST', url, true);
    httpRequest.setRequestHeader('Content-Type', 'application/json');
    httpRequest.send(JSON.stringify(tracking));

    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState == XMLHttpRequest.DONE) {
            if (httpRequest.status == 200) {
                return
            }
        }
    }
}

const replaceAll = (find, replace, string) => {
    let re = new RegExp(find, 'g');
    string = string.replace(re, replace);
    return string
}

const getParam = (param) => {
    const url = location.href;
    param = param.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+param+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? null : results[1];
}

const initData = () => {
    let campaigns = decodeURI(getCookie(cookiesPrefix + '_campaign'))
    campaigns = replaceAll('%3A', ':', campaigns)
    campaigns = replaceAll('%2C', ',', campaigns)
    campaigns = JSON.parse(campaigns)

    let campaignsInfo = []
    for( let campaign in campaigns){
        if(cookiesCurrentCampaigns.includes(campaign)){
            campaignsInfo.push({
                id: campaign,
                visit: campaigns[campaign]
            })
        }        
    }

    let tracking = {
        cookieID: getCookie(cookiesPrefix),
        pageVersion: document.body.getAttribute('data-version'),
        url : window.location.href,
        referrer: document.referrer,
        campagne: campaignsInfo,
        utmSource: getParam('utm_source'),
        utmMedium: getParam('utm_medium'),
        utmCampaign: getParam('utm_campaign'),
        utmTerm: getParam('utm_term'),
        utmContent: getParam('utm_content'),
        agent: getAgent(),
        timestamp : {
            begin : new Date().getTime()
        }
    }

    let utm = []
    let parseUrl = location.search.split('&')
    parseUrl.forEach(utmString => {
        let utmOption = RegExp('([^?&#;]*)=([^?&#;]*)').exec(utmString)
        if(utmOption != null){
            utm.push({
                parameter: utmOption[1],
                content: utmOption[2]
            })
        }        
    });

    if(utm.length > 0) tracking.utm = utm

    return(tracking)
}

export default (path) => {

    const leave = (event) => {
        tracking.scroll = stepScroll
        tracking.clicks = clicks
        tracking.mouse = mouseMovement
        tracking.timestamp.end = new Date().getTime()
        tracking.eventClose = 'leave'
        save(tracking, path)
    }

    let tracking = initData()
    let clicks = []
    let currentMousePath = ""
    let tickMouse = false
    let tickScroll = false

    if(cookiesTracking.includes('scroll') || cookiesTracking.includes('move')){
        setInterval(function() {
            if(tickScroll) {
                tickScroll = false;
                trackScroll()
            }
            if(tickMouse) {
                tickMouse = false
                trackMouse(currentMousePath)
            }
        }, 150);
    }    

    if(!tracking.agent.includes('Mobile') && cookiesTracking.includes('move')){
        document.addEventListener('mousemove', (ev) => {
            tickMouse = true;
            currentMousePath = ev.target
        })
    }    

    if(cookiesTracking.includes('scroll')){
        stepScroll.push(
            {
                step: 0,
                timestamp: new Date().getTime()
            }
        )
        document.addEventListener('scroll', (ev) => {
            tickScroll = true;
        }, {
            passive: true
        })
    }

    document.addEventListener('click', (ev) => {
        if(ev.target.nodeName == 'BUTTON'){
            if(cookiesTracking.includes('click')){
                clicks.push({
                    element: ev.target.id,
                    timestamp: new Date().getTime()
                })
            }
            if(ev.target.type == 'submit' && ev.target.id != "cookiebox-submit"){
                window.removeEventListener("beforeunload", leave);
                tracking.scroll = stepScroll
                tracking.clicks = clicks
                tracking.mouse = mouseMovement
                tracking.timestamp.end = new Date().getTime()
                tracking.eventClose = 'submit'
                save(tracking, path)
            }
        }        
    })

    window.addEventListener("beforeunload", leave);
}